<template>
  <div>

  <div @click="closeDrop($event)">
    <!-- <Header :showDeepPlan="showDeepPlan" /> -->
    <MenuFilter v-if="filter" :menu="menu" :bgColor="bgColor" :routeData="filteredData"></MenuFilter>

    <!--Header component-->
    <component :is="headerComponent" :playerInstance="playerInstance" :isAuth="isAuthOpen"></component>
    <!--router-view-->
    <router-view
      :class="[currentRoute === 'detail' ? 'disable-margin' : 'enable-margin', 'body-min-width']"
      :playerInstance="playerInstance"
      @openAuth="(value) => toggleAuthPopup(value)"
    />
 
    <!-- Server Down page -->
    <ServerDown v-if="isServerDown"></ServerDown>

    <!-- Mobile App down load page -->
    <!-- <MobileWeb v-if="isMobileView && !isServerDown"></MobileWeb> -->
    <!--Footer component-->
    <component
      :is="footerComponent"
      :showDeepAbout="showDeepAbout"
      :showDeepHelp="showDeepHelp"
    ></component>

    <transition>
      <component
        :is="popupComponent"
        :formInfo="authInfo"
        :content="content"
        :menu="menu"
        :closePopup="() => closeThePopup()"
        :closePlayerEvent="() => (popupComponent = null)"
      ></component>
    </transition>
    <PlayerComponent
      v-show="isPlayerVisible"
      :content="content"
      :seriesData="seriesData"
      :playerInstance="playerInstance"
      :closePlayerEvent="handlePlayerCloseEvent"
    />
    <transition>
      <overlayLight v-if="showOverlay"></overlayLight>
    </transition>

  </div>
  <RzpModal :onOpen="showRzpModal" :plan="plan" :onClose="() => this.showRzpModal = false"/>

  <Popup  v-if="isActiveSuccessfullPayment" width="30%" margin="15% auto" radiusType="full" :closePopupEvent="() => { closePopupnRefreshPage() }">
    <div class="confirmation-success" slot="component" >
      <div  class="confirmation-title">
        <h5>Plan subscription is successfull</h5>
        <div class="plan-code-style">
          <h5>{{ isActiveSuccessfullPayment.planname }}</h5>
          <p v-html="currencyCodeSubscriber(isActiveSuccessfullPayment)"></p>
        </div>

        <div class="login-button">
          <button class="button-primary login" type="Button" @click.stop="closePopupnRefreshPage">
            {{ $t("Continue Watching") }}
          </button>
        </div>
    </div>
    </div>
  </Popup>

  <PinModal
    id="pc-pin-init-modal"
    :open="showPinModal"
    @onClose="closePinModal"
    :mode="pinMode"
    @onActionComplete="onPinModalActionComplete"
    :pinError="pinModalError"
    :callbackParentalLockFlag="callbackParentalLockFlag"
    :loading="modalLoading"
    @onForgotPin="openForgotPinModal"

  />

  <ForgotPinModal
    id="pc-forgot-pin-modal"
    :open="showForgotPinModal"
    @onClose="closeForgotPinModal"
    @onActionComplete="onForgotPinModalActionComplete"
    :pinError="forgotPinModalError"
    @onResend="resendOtp"
    :loading="loading"
  />
    
  <AgeRestrictionModal v-if="showAgePopUp" />

  <PreferredLang v-if="showPreferredLanguage" />
</div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters, mapActions } from "vuex";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import { vLivePlayer } from "/src/vue-player/vLiveWebPlayer.js";
import { store } from "@/store/store";
import jwt_decode from "jwt-decode";
import { getFreshTokenUpdate } from '@/store/refershTokenInterval'
export default {
  name: "Init",
  data() {
    return {
      mobileBrowserController: null,
      isMobileView: false,
      filter: false,
      menu: null,
      isPlayerVisible: false,
      content: null,
      toggleAuth: false,
      authInfo: {},
      enableAuth: true,
      isOverlay: false,
      showDeepHelp: null,
      showDeepAbout: null,
      showDeepPlan: null,
      showOverlay: false,
      headerComponent: null,
      footerComponent: null,
      popupComponent: null,
      currentRoute: null,
      bgColor: null,
      modalLoading: false,
      filteredData: null,
      playerInstance: null,
      seriesData: null,
      isAuthOpen: false,

      availabilityList: [],
      page: 1,
      pageSize: 15,
      isServerDown: false,
      showPinModal: false,
      pinModalError: '',
      pinMode: 'restrict',
      callbackParentalLockFlag: '',
      ParentalLockDataset: '',
      showRzpModal: false,
      plan: null,
      isActiveSuccessfullPayment: false,
      showForgotPinModal: false,
      forgotPinModalError: "",
      loading: false,
      showAgePopUp: false,
      showPreferredLanguage: false
    };
  },
  computed: {
    ...mapGetters(["appConfig", "subscriberid", "profileid"]),
  },
  watch: {
    $route(to, from) {
      //logging screen view google analytics event.
      let GA = { screen_name: to.name };
      this.pageViewEvent(GA);

      this.closePlayerPopup(to, from);

      // call methods based on routes.
      this.invokeMethodsBasedOnRoutes();
      let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");
      let isMultipleProfileEnabled = localStorage.getItem("isMultipleProfileEnabled");
      let subscriptionDetails = localStorage.getItem("subscriptionDetails");
      if(subscriberProfileDetails === null || subscriberProfileDetails === undefined){
        if(!isMultipleProfileEnabled){
          return;
        }
        if(subscriptionDetails === null || subscriptionDetails === undefined){
          return;
        }
        let currentLanguage = this.getCurrentLanguageUrlBase();
      	// this.$router.push({name: "switchProfile", params: { lang: currentLanguage, renderCancel: false }})
      }
    },

    subscriberid(val) {
      if (val) {
        this.fetchAvailability();
        this.loadHeaderFooter();
      }
    },

    profileid(val) {
      if(val) {
        this.loadHeaderFooter();
      }
    }
  },

  created() {
   
    eventBus.$on('preferred-lang-visibility', (data) => {
        this.showPreferredLanguage = data;
    })

    eventBus.$on('event-parental-lock-CB', (dataset) => {
     this.showPinModal = true
     this.ParentalLockDataset = dataset
    })

    let convertingMobileViewControllerToBoolean = JSON.parse(this.appConfig.mobileViewController.toLowerCase());
    this.mobileBrowserController = convertingMobileViewControllerToBoolean;

    // Load header and footer.
    this.loadHeaderFooter();

    if(this.appConfig && this.appConfig.featureEnabled.isServerDown) {
      this.isServerDown = true;
      return;
    }

    // if (sessionStorage.getItem("isActiveDesktopView")) {
    //   sessionStorage.removeItem("isActiveDesktopView");
    // } else {
    //   this.detectMobile();
    // }
    //this.checkMobileWeb();
    this.renderAuth();
    this.branchDeepLinks();

    this.fetchAvailability();

    eventBus.$on("check-for-series-route", () => {
      this.fetchAvailability();
    });

    this.invokeMethodsBasedOnRoutes(); // calling methods based on routes.
    //show category filter in detail page
    eventBus.$on("show-category-filter", (payload) => {
      this.filter = payload.state;
      this.menu = payload.menu;
      this.bgColor = payload.bgColor;
      this.filteredData = payload.title;
    });
    eventBus.$on("successfullpayment-event", (dataset) => {
      this.isActiveSuccessfullPayment = dataset;
    });

    eventBus.$on("player-visibility", (isVisible) => {
      if (isVisible) {
        document.querySelector("body").style.overflow = "hidden";
      } else {
        document.querySelector("body").style.overflow = "auto";
      }
      this.isPlayerVisible = isVisible;
    });

    eventBus.$on("triggerInitView", (payload) => {
      this.isMobileView = false;
      this.emitToSwitchHeader();
    });

    eventBus.$on("load-player-content", (response) => {
      this.content = response.content;

      if (response.seriesContent) {
        this.seriesData = response.seriesContent;
      }
      // this.seriesData = content;
    });

     eventBus.$on("vlive-player-ready", (event) => {
        let config = {
        platform: "vliveweb",
        skipTime: 10,
        progressColor: "#FF9F0A",
        tokenApiURL: this.appConfig.providerDetails.drmTokenUrl,
        orientation: this.$i18n.locale === "ara" ? "rtl" : "ltr",
      };

      let playerSettings = {
        container: "vlive-player",
        showSettings: true,
        showCast: false,
        showPlaylist: true,
        hasWatermark: true,
        watermark: 'https://static-templates.web.app/img/ultrawhite.png',
        captionLang: "ar",
        audioLang: "tr",
        displayLang: this.$i18n.locale,
        fairplayCertificate: "/static/noorplayfairplay.cer",
        playerConfig: config,
      };

      if (this.$i18n.locale == "hi") {
        playerSettings.localeStrings = {
          qualityTitle: "क्वालिटी",
          audioTitle: "ऑडिओ",
          subtitleTitle: "सबटायटल्स",
          offText: "बंद",
          autoText: "ऑटो",
          skipIntro: "स्किप इंट्रो",
          nextEpisodeTimerText: "पुढील भाग %@ नंतर सुरु होईल",
          nextTrackTimerText: "पुढील ट्रॅक %@ सेकंद नंतर",
          nextEpisode: "पुढील भाग",
          nextTrack: "पुढील ट्रॅक",
          nextMovie:  "पुढचा चित्रपट",
          en: "इंग्रजी",
          normal: "सामान्य",
          PlaybackSpeed: "प्लेबॅक स्पीड",
          nowPlaying : "आता सुरु आहे",
          playList: "प्ले यादी"
        };
      } else {
        playerSettings.localeStrings = {
          qualityTitle: "QUALITY",
          audioTitle: "AUDIO",
          subtitleTitle: "SUBTITLES",
          offText: "Off",
          autoText: "Auto",
          skipIntro: "SKIP INTRO",
          nextEpisodeTimerText: "Next Episode starts after %@",
          nextTrackTimerText: "Next Track starts after %@",
          nextEpisode: "NEXT EPISODE",
          nextTrack: "NEXT TRACK",
          nextMovie: "NEXT MOVIE",
          normal: "Normal",
          PlaybackSpeed: "PLAYBACK SPEED",
          nowPlaying: "Now Playing",
          playList: "Play List"
        };
      }
      this.playerInstance = new vLivePlayer(playerSettings, []);
    });

    eventBus.$on("check-local-storage", (localeLang) => {
      let playerSettings = {};
      let config = {
        platform: "vliveweb",
        skipTime: 10,
        progressColor: "#FF9F0A",
        tokenApiURL: this.appConfig.providerDetails.drmTokenUrl,
        orientation: this.$i18n.locale === "ara" ? "rtl" : "ltr",
      };

      playerSettings.playerConfig = config;

      playerSettings.displayLang = this.$i18n.locale;

      if (this.$i18n.locale == "ara") {
        playerSettings.localeStrings = {
        };
      } else {
        playerSettings.localeStrings = {
          qualityTitle: "QUALITY",
          audioTitle: "AUDIO",
          subtitleTitle: "SUBTITLES",
          offText: "Off",
          autoText: "Auto",
          skipIntro: "SKIP INTRO",
          nextEpisode: "NEXT EPISODE",
        };
      }

      this.playerInstance.settings = playerSettings;
    });

    eventBus.$on("open-home-rzp-modal", (plan) => {
      this.showRzpModal = true;
      this.plan = plan;
    });

    eventBus.$on("open-age-restriction-modal", (condition) => {
      this.showAgePopUp = condition;
      // this.plan = plan;
    });
  },
  mounted() {

    window.myIntervalreFreshToken = "";
    
    eventBus.$on("toggleMenuFilter", (response) => {
      // this.filter = response.state;
      if (response.state) {
        this.menu = response.menu;
        // this.filter = true;
        this.popupComponent = () =>
          import(/* webpackChunkName: "MenuFilter" */ "@/components/Templates/categoryFilter.vue");
      }
    });

    eventBus.$on("authPopup", (response) => {
      // this.toggleAuthPopup(response.state);

      if (response.state) {
        this.authInfo = { ...response };
        this.authInfo.formType = response.formType;
        this.authInfo.previousAction = response.previousAction;
        this.popupComponent = () =>
          import(/* webpackChunkName: "auth" */ "@/components/Popups/Authentication/userAuth.vue");
      }
    });

    eventBus.$on("enableOverlayLoader", (state) => {
      // this.isOverlay = state;
      if (state) {
        this.popupComponent = () =>
          import(/* webpackChunkName: "overlay" */ "@/components/Templates/overlayLoader.vue");
      } else {
        this.popupComponent = null;
      }
    });

    eventBus.$on("show-light-overlay", (state) => {
      this.showOverlay = state;
    });

    this.registerErrorEventForDeviceToken();
    this.getFreshToken();
  },
  beforeDestroy() {
    eventBus.$off("check-for-series-route");
    eventBus.$off("show-light-overlay");
  },
  methods: {
    ...mapActions(["parentalControlVerifyOldPin", "parentalControlPinSet", "parentalControlResetPin"]),
    getFreshToken() {
      if (localStorage.getItem("refreshtoken")) {
        getFreshTokenUpdate();
      }
 
    },
    resendOtp() {
      //do something here. (Make call to the API)
      this.loading = true;
      let payload = {
        otp_type: "parentalControlPinUpdate",
      };
      //do something here. (Make call to the API)
      //received otp to email
      this.parentalControlPinSet(payload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.pinModalError = this.$t(data.data.reason);
            return;
          } else if (data.data.success) {
            console.log("parentalControlPinSet", data);
          }
        })
        .catch((error) => {
          console.log("parentalControlPinSet error -> ", error);
        });
    },
    closeForgotPinModal() {
      this.showForgotPinModal = false;
    },
    onForgotPinModalActionComplete(data) {
      // forget pin reset api call
      this.loading = true;
      let forgetPinPayload = {
        pin: data.pin,
        OTP: data.otp,
      };
      this.parentalControlResetPin(forgetPinPayload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.forgotPinModalError = this.$t(data.data.reason);
            return;
          } else if (data.data.success) {
            this.showForgotPinModal = false;
          }
        })
        .catch((error) => {
          console.log("parentalControlResetPin error -> ", error);
        });
    },
    openForgotPinModal() {
      this.showForgotPinModal = true;
      this.loading = true;
      //call api to get otp
      let payload = {
        otp_type: "parentalControlPinUpdate",
      };
      //do something here. (Make call to the API)
      //received otp to email
      this.parentalControlPinSet(payload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.pinModalError = this.$t(data.data.reason);
            return;
          } else if (data.data.success) {
            console.log("parentalControlPinSet", data);
          }
        })
        .catch((error) => {
          console.log("parentalControlPinSet error -> ", error);
        });
    },
    closePopupnRefreshPage () {
      this.isActiveSuccessfullPayment = false;
      window.location.reload();
    },
    currencyCodeSubscriber (plan) {
      return  plan.currency == "INR" ? "<span>&#8377;</span>" + " " + (plan.amount / 100) : "<span>&#36;</span>" + " " + (plan.amount / 100);
    },
    onPinModalActionComplete(enteredPin) {
      let payload = {
          pin: enteredPin,
        };
        this.modalLoading = true;
        this.parentalControlVerifyOldPin(payload)
          .then((data) => {
            if (data.data.reason) {
              this.pinModalError = this.$t(data.data.reason);
            this.modalLoading= false;

              return;
            } else if (data.data.success) {

              this.closePinModal();
            this.modalLoading= false;

              if (this.ParentalLockDataset.includes('continue_watch')) {
                  document.getElementById(this.ParentalLockDataset).click();
              } else {
                eventBus.$emit("event-callback-parentallock", this.ParentalLockDataset)

              }
            }
          });
    },
    closePinModal() {
      this.showPinModal = false;
      this.callbackParentalLockFlag = '';
    },
    emitToSwitchHeader() {
      eventBus.$emit("switchHeaderBack");
    },
    getCurrentLanguageUrlBase(){
      let lang = localStorage.getItem("setDisplayLanguageCode");
      let defaultLanguage = window.PROVIDER_SETUP_CONFIG.defaultLanguage;
      let supportedLanguages = window.PROVIDER_SETUP_CONFIG.supportLanguageList;

      if (lang === defaultLanguage) {
        return null;
      }
      for(let index = 0; index < supportedLanguages.length; index ++){
        let languageItem = supportedLanguages[index];
        if(lang === languageItem){
          return languageItem;
        }
      }
      return 'en';
    },
    closeThePopup() {
      this.popupComponent = null;

      if (!this.subscriberid) {
        eventBus.$emit("close-plan-popup", true);
      }
    },
    
    detectMobile() {
      let isMobile = "";
      if (
        this.mobileBrowserController &&
        this.$route.name !== "Profile" &&
        this.$route.name !== "partner-switching" &&
        this.$route.name !== "telcoPaymentHandler" && 
        this.$route.name !== "contentPlayDeeplink" &&
        this.$route.name !== "seeAllPlans" && 
        this.$route.name !== "planandcoupondeeplink" &&
        this.$route.name !== "termsofuse" &&
        this.$route.name !== "privacypolicy" &&
        this.$route.name !== "termsofuse-ar" &&
        this.$route.name !== "privacyPolicy-ar"  &&
        this.$route.name !== "loginWithPinView" 
      ) {
        isMobile = RegExp(/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent);

        if (!isMobile) {
          const isMac = RegExp(/Macintosh/i).test(navigator.userAgent);

          if (isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
            isMobile = true;
            
          }
        }
        if (isMobile) {
          this.isMobileView = true;
          localStorage.setItem("isMobile", true);
        } else {
          this.isMobileView = false;
        }
      }
    },

    closePlayerPopup(to, from) {
      if (from !== to && this.isPlayerVisible) {
        this.isPlayerVisible = false;
      }
    },

    registerErrorEventForDeviceToken() {
      eventBus.$on("deviceRegister_error", (response) => {
        if (this.$route.name === "partner-switching") {
          let data = {
            partner_name: this.$route.params && this.$route.params.id ? this.$route.params.id : "",
            error_type: "Device_Register",
            error_code: response.errorcode ? response.errorcode : response,
            error_message: response.reason ? response.reason : response,
          };
          this.telco_error_event(data);
        }
      });
    },
    toggleAuthPopup(value) {
      console.log("toggled auth popup", value);
      this.isAuthOpen = value;
    },
    loadHeaderFooter() {
      (this.headerComponent = () => import(/* webpackChunkName: "header" */ "@/components/Header/Header.vue")),

        (this.footerComponent = () =>
          import(/* webpackChunkName: "footer" */ "@/components/Footer/noorplay_Footer.vue"));
    },
    handlePlayerCloseEvent() {
      console.log("player closed from init");
      eventBus.$emit("player-closed");
    
      this.isPlayerVisible = false;

      document.querySelector("body").style.overflow = "auto";

      this.content = null;
    },
    deniedRoutes() {
      this.currentRoute = this.$router.currentRoute.name;
      if (this.currentRoute === "detail") {
        this.headerComponent = null;
        this.footerComponent = null;
      } else {
        this.loadHeaderFooter();
      }
    },
    renderAuth() {
      let currentRoute = this.$router.currentRoute;
      if (currentRoute && currentRoute.params) {
        if (currentRoute.params.link === "content") {
          this.enableAuth = false;
        } else {
          this.enableAuth = true;
        }
      } else {
        this.enableAuth = true;
      }
    },
    closeDrop(event) {
      if (event.target.id !== "searchCat" && event.target.id !== "searchName" && event.target.id !== "searchArrow") {
        eventBus.$emit("toggle-SearchCat", false);
      }
      if (
        event.target.id !== "searchInput" &&
        event.target.id !== "searchIcon" &&
        event.target.id !== "search" &&
        event.target.id !== "suggestion-items" &&
        event.target.id !== "search-history" &&
        event.target.id !== "history-item" &&
        event.target.id !== "del-history" &&
        event.target.id !== "play-imgs" &&
        event.target.id !== "play-img-item" &&
        event.target.id !== "searched-container" &&
        event.target.id !== "searched-container-box" &&
        event.target.id !== "searched-container-para"
      ) {
        this.showOverlay = false;
        // eventBus.$emit("show-light-overlay", false);
        eventBus.$emit("toggle-SearchInput", false);
      }
      if (event.target.id !== "hamburger-icon") {
        eventBus.$emit("toggle-MenuDrop", false);
      }
      if (
        event.target.id !== "arrow" &&
        event.target.id !== "arrow-container" &&
        event.target.id !== "profile-info-admin" &&
        event.target.id !== "profile-info-text" &&
        event.target.id !== "profile-info-container" &&
        event.target.id !== "profile-center-container" &&
        event.target.id !== "profile-drop" &&
        event.target.id !== "profile-icon" &&
        event.target.id !== "noShowProfile-container" &&
        event.target.id !== "noShowProfile" &&
        event.target.id !== "profile-ul-list" &&
        event.target.id !== "profile-container"
      ) {
       eventBus.$emit("toggle-profile-drop", false);
      }
      if (event.target.id !== "small-genre") {
        eventBus.$emit("toggle-small-genre", false);
      }
      if (event.target.id !== "small-language") {
        eventBus.$emit("toggle-small-language", false);
      }
      if (event.target.id !== "small-time") {
        eventBus.$emit("toggle-small-time", false);
      }
      if (
        event.target.id !== "tab-Related" &&
        event.target.id !== "tab-Trailers" &&
        event.target.id !== "tab-Items" &&
        event.target.id !== "rel-contain" &&
        event.target.id !== "rel-contain-title"
      ) {
        eventBus.$emit("close-related-tab", false);
      }

      if (
        event.target.id !== "notification-drop-parent" &&
        event.target.id !== "noShowNotify" &&
        event.target.id !== "notify-drop"
      ) {
        eventBus.$emit("close-notification-drop", false);
      }
    },
    branchDeepLinks() {
      if (this.$router.currentRoute.path === "/lookup") {
        if (!this.subscriberid) {
          this.toggleAuth = true;
          this.authInfo.formType = "lookup";
        }
      } else if (this.$router.currentRoute.path === "/help") {
        this.showDeepHelp = true;
      } else if (this.$router.currentRoute.path === "/about") {
        this.showDeepAbout = true;
      } else if (this.$router.currentRoute.path === "/menu/watchlist") {
        if (!this.subscriberid) {
          this.$router.push({ name: "Home" });
        }
      } else if (this.$router.currentRoute.path === "/menu/plan") {
        if (!this.subscriberid) {
          this.$router.push({ name: "Home" });
        } else {
          let payload = {
            listPlans: "ALL",
          };
          this.showDeepPlan = true;
        }
      } else if (this.$router.currentRoute.path === "/menu/bold") {
        if (localStorage.getItem("subscriberDetails")) {
          let details = JSON.parse(localStorage.getItem("subscriberDetails"));
          if (details.age < 18) {
            this.$router.push({ name: "Home" });
          } else {
            let menuDetail = this.appConfig.menu[2];
            this.$router.push({ name: "Bold", params: { menuDetail } });
          }
        }
      }
    },
    invokeMethodsBasedOnRoutes() {
      let currentRoute = this.$router.currentRoute.name;
      if (currentRoute !== "partner-switching") {
        this.checkForMovieResponse();
        // this.fetchAvailability();
      }
    },
    checkForMovieResponse() {
      let appconfig = JSON.parse(localStorage.getItem("appConfig"));
      let moviesSection = appconfig.screens.filter((el) => {
        return el.id === "MOVIES";
      });
      let screen = moviesSection[0].sections[0];

      let payload = {
        endpoint: screen.endpoint,
        params: screen.parameters,
      };

      store
        .dispatch("contentList", payload)
        .then((response) => {
          if (response.data.errorcode === 7109) {
            store.commit("setIsMovieEnabled", "No");
          } else {
            store.commit("setIsMovieEnabled", "Yes");
          }
        })
        .catch((error) => console.log(error));
    },
    fetchAvailability() {
      this.page = 1;

      this.availabilityList = [];

      let payload = {
        page: this.page,
        pagesize: this.pageSize,
      };

      this.fetchAvailabilityFunc(payload);
    },

    fetchAvailabilityFunc(payload) {
      store
        .dispatch("availabilityList", payload)
        .then((response) => {
          //storing total count in local variable.
          let totalCount = response.data.totalcount;
          //appeding availability response for the exising array.
          this.availabilityList = [...this.availabilityList, ...response.data.data];
          //commit the updated availability list.
          store.commit("setAvailabilityList", this.availabilityList);

          //checking if the lenght of availabilties less than total count. If true call availability list agian.
          if (this.availabilityList.length < totalCount) {
            this.page++;

            const newPayload = {
              page: this.page,
              pagesize: this.pageSize,
            };

            this.fetchAvailabilityFunc(newPayload);
          }

          if (window.activeDeeplinkCoupon && this.$route.path != 'switchprofile' ) {
            setTimeout(() => {
              // window.activeDeeplinkCoupon = ""
              this.$router.push({ path: window.activeDeeplinkCoupon})
            }, 500);
           }
        })
        .catch((error) => console.log(error));
    },
  },
  components: {
    MobileWeb: () => import(/* webpackChunkName: "mobileWeb" */ "@/components/MobileWeb/mobileWeb.vue"),
    ServerDown: () => import("@/components/ServerDown/ServerDown.vue"),
    MenuFilter: () => import(/* webpackChunkName: "MenuFilter" */ "@/components/Templates/categoryFilter.vue"),
    overlayLight: () => import(/* webpackChunkName: "overlayLight" */ "@/components/Overlay/Overlay.vue"),
    PlayerComponent: () => import(/* webpackChunkName: "Player" */ "@/components/Popups/player.vue"),
    PinModal: () => import("@/components/utils/PinModal.vue"),
    RzpModal: () => import(/* webpackChunkName: "RzpPayment" */ "@/components/Popups/Payment/RzpPayment.vue"),
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    ForgotPinModal: () => import("@/components/utils/ForgotPinModal.vue"),
    AgeRestrictionModal: () => import(/* webpackChunkName: "ageRestrictionModal" */ "@/components/Popups/Restriction/AgeRestriction.vue"),
    PreferredLang: () => import(/* webpackChunkName: "prefLang" */ "@/components/Language/PreferredLanguage.vue")
  },
  mixins: [googleAnalytics],
};
</script>

<style scoped>
@import "./Init.scss"
</style>
